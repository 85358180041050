<template>
    <section class="component-container" :id="cmsBlock.anchorTag">
        <div class="text-block" :class="[cmsBlock.wysiwigWidth, cmsBlock.wysiwygStyle]">
            <div class="block-content" :class="cmsBlock.wysiwygStyle">
                <div v-html="md(localize(cmsBlock.text))" class="cms-content"></div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'TextBlock',

    props: [
        'cmsBlock'
    ]
}
</script>

<style lang="scss" scoped>
.component-container {
    .text-block {
        width: calc(100% - 2 * getSpacing('padding-default-horizontal', 'desktop'));
        max-width: calc($container-width - 2 * getSpacing('padding-default-horizontal', 'desktop'));
        margin: 0 auto;
        padding: 0 getSpacing('padding-default-horizontal', 'desktop');

        @include breakpoint('tablet') {
            width: calc(100% - 2 * getSpacing('padding-default-horizontal', 'tablet'));
            padding: 0 getSpacing('padding-default-horizontal', 'tablet');
        }

        @include breakpoint('mobile') {
            width: 100%;
            padding: 0 0;
        }

        .block-content {
            margin: 0 auto;
            max-width: $container-width;
            width: calc(100% - 2* getSpacing('padding-default-horizontal', 'desktop'));
            padding: 0 getSpacing('padding-default-horizontal', 'desktop');

            @include breakpoint('tablet') {
                width: calc(100% - 2* getSpacing('padding-default-horizontal', 'tablet'));
                padding: 0 getSpacing('padding-default-horizontal', 'tablet');
            }

            @include breakpoint('mobile') {
                width: calc(100% - 2* getSpacing('padding-default-horizontal', 'mobile'));
                padding: 0 getSpacing('padding-default-horizontal', 'mobile');
            }
        }
    }

    .full_width {
        max-width: none;
        width: 100%;
        padding: 0;
    }
}
</style>
